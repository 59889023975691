import { Redirect, Route } from 'react-router-dom';
import { useContext } from "react";

import {
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
} from '@ionic/react';

import { search, list, exit, callOutline } from 'ionicons/icons';

import Perizie from '../pages/Perizie';
import PeriziaDetail from '../pages/PeriziaDetail';
import PeriziaEval from '../pages/PeriziaEvaluation';
import Search from '../pages/Search';
import LoginPage from '../pages/Login';

import TabMenu from 'components/CustomTabs';

import PrivateRoute from '../utils/PrivateRoute'

import AuthContext from '../context/AuthContext'


const callAssistenza = (e) => {
    e.preventDefault()
    window.open("tel:324915573");
  }

const CustomTabs = () => {
  // Se l'utente non e' autenticato rimando alla pagina di login e quindi nascondo la tabbar
  let { user } = useContext(AuthContext)
  let tabBarStyle = user ? undefined : { display: "none" };
  return (
      <div>
          <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/perizia/:periziaId/eval/:step" component={PeriziaEval} />
            <Route exact path="/perizia/:periziaId" component={PeriziaDetail} />
            <Route path="/perizie">
              <PrivateRoute><Perizie/></PrivateRoute>
            </Route>
            <Route path="/search">
            <PrivateRoute><Search /></PrivateRoute>
            </Route>
            <Route exact path="/">
              <Redirect to="/perizie"/>
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom" style={tabBarStyle}>
              <IonTabButton tab="perizie" href="/perizie">
              <IonIcon aria-hidden="true" icon={list} />
              <IonLabel>Perizie</IonLabel>
              </IonTabButton>
              <IonTabButton tab="search" href="/search">
              <IonIcon aria-hidden="true" icon={search} />
              <IonLabel>Cerca</IonLabel>
              </IonTabButton>
              <IonTabButton onClick={callAssistenza} href="tel:324915573">
              <IonIcon aria-hidden="true" icon={callOutline} />
                  <IonLabel>Assistenza</IonLabel>
              </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </div>
  )
};

export default CustomTabs;



