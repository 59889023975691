import { useState, useEffect } from 'react';
import { IonButton } from '@ionic/react';
import {
    IonCol,
} from '@ionic/react';
import axios from 'axios';

const BackNextButton = ({perizia, step}) => {
    // uso useState per mostrare/nascondere i pulsanti a seconda dello step in cui siamo
    var [backButton, setBackButton] = useState('hidden')
    var [nextButton, setNextButton] = useState('show')
    var [confirmButton, setConfirmButton] = useState('hidden')

    useEffect(() => {
        if (step > 0) {
            setBackButton('show');
            setConfirmButton('show');
            setNextButton('hidden');
        }
        else{
            setBackButton('hidden');
            setConfirmButton('hidden');
            setNextButton('show');
        }
    }, [step]);

    return (
        <>
        <IonCol>
            {<IonButton className={backButton} routerLink={`/perizia/${perizia?.id}/eval/0`} color='light' expand='full'>Indietro</IonButton>}
        </IonCol>
        <IonCol>
            {<IonButton className={nextButton} routerLink={`/perizia/${perizia?.id}/eval/1`} color='primary' expand='full'>Avanti</IonButton>}
            {<IonButton className={confirmButton} routerLink={`/perizia/${perizia?.id}/eval/1`} color='success' id='confirm-button' expand='full'>Salva</IonButton>}
        </IonCol>
        </>
    )
}

export default BackNextButton;
