import { IonAlert } from '@ionic/react';

function WarningAlert(props) {
  return (
    <>
      <IonAlert
        header='WARNING'
        message={props.message}
        trigger='confirm-button'
        buttons={[
          {
            text: 'OK',
            role: 'cancel'
          }
        ]}
      ></IonAlert>
    </>
  );
}
export default WarningAlert;
