import axios from "axios";

let isRefreshing = false;
const jwtInterceptor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

jwtInterceptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem("authTokens"));
  if (tokensData) {
    config.headers['Authorization'] = `Bearer ${tokensData.access}`;
  }
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error)
    if (error.response.status === 401 && !isRefreshing) {
      isRefreshing = true;
      let originalRequest = error.config;
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      if (!authTokens?.refresh) {
        window.location.href = "/login";
      }
      const payload = {refresh:authTokens?.refresh}
      await jwtInterceptor.post(
          "/api_sinistri_auto/token/refresh/",
          payload
      ).then((apiResponse) => {
        isRefreshing = false;
        localStorage.setItem("authTokens", JSON.stringify(apiResponse.data));
        originalRequest.headers.authorization = `Bearer ${apiResponse.data.access}`;
        return jwtInterceptor(originalRequest);
      }).catch(function (error) {
        console.log(error)
        window.location.href = "/login";
      });
 /*     if (apiResponse.data){
          isRefreshing = false;
          localStorage.setItem("authTokens", JSON.stringify(apiResponse.data));
          originalRequest.headers.authorization = `Bearer ${apiResponse.data.access}`;
          return jwtInterceptor(originalRequest);
      }
      else{
        window.location.href = "/login";
      }*/
    } else {
      return Promise.reject(error);
    }
  }
);
export default jwtInterceptor;
