import { useEffect, useState } from 'react';

import { IonButton, IonCol, IonIcon } from '@ionic/react';
import { download } from 'ionicons/icons';


const DownloadButton = ({ perizia, uploaded }) => {
    const [ hasButton, setHasButton ] = useState(false);
    const [ fileLink, setFileLink ] = useState('');

    useEffect(() => {
        let ac = perizia.accordo_conservativo
        if (ac) {
            setHasButton(true);
            setFileLink(ac);
        }
    }, [uploaded]);

    return( hasButton && <IonButton
        href={fileLink} onClick={(e) => e.stopPropagation()}
        download={`accordo_conservativo_${perizia.id}.pdf`}
        target='_blank'>
        <IonIcon icon={download}></IonIcon> PDF</IonButton>)
}

export default DownloadButton;
