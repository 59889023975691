import {IonToolbar,
        IonImg,
        IonHeader,
        IonTitle,
        IonGrid,
        IonRow,
        IonCol,
        IonButton,
        IonIcon,
} from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import { useContext } from "react";
import '../style/components/myheader.css';

import AuthContext from "../context/AuthContext";


const MyHeader = ({title=''}) => {
    const { logoutUser } = useContext(AuthContext);
    // const logos = {
    //     'rentek': 'assets/img/rentek_logo_blu.png',
    //     'biauto': 'assets/img/biauto_logo.jpeg',
    //     'wefox': 'assets/img/wefox_logo.jpeg',
    // }
    // var params = useParams();
    // var logo = logos[params['company']];
    var logo = 'assets/img/rentek_logo_blu.png';

    return (
        <IonHeader>
            <IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol size="3">
                            <IonImg className="logo" src={logo} alt=""></IonImg>
                        </IonCol>
                        <IonCol>
                            <IonTitle className="header-title text-center">{ title }</IonTitle>
                        </IonCol>
                        <IonCol size="3" className='ion-text-end'>
                            <IonIcon icon={logOutOutline} onClick={logoutUser} className='logout-icon'></IonIcon>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonHeader>
    )
};

export default MyHeader;
