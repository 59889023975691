import { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';

import { IonPage,
    IonContent,
    IonItem,
    IonButton,
    IonGrid,
    IonRow,
    IonIcon,
    IonThumbnail,
    IonList,
    IonListHeader,
    IonLabel,
   } from '@ionic/react';
import { camera } from 'ionicons/icons';

import { Preferences } from '@capacitor/preferences';

import jwtInterceptor from '../utils/jwtInterceptor';

import MyHeader from '../components/MyHeader';
import ConfirmAlert from 'components/ConfirmAlert';
import WarningAlert from "../components/WarningAlert";
import BackNextButton from 'components/BackNextButton';

import { usePhotoGallery } from '../hooks/usePhotoGallery';
import { compare_arrays } from "../utils/PerizieUtils";

import '../style/pages/perizia_eval.css';

const PeriziaEval = ({ match }) => {

    const baseURL = `/api_sinistri_auto/sinistro/${match.params.periziaId}/`;

    const [perizia, setPerizia] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isComplete, setComplete] = useState(false)

	useEffect(() => {
		setLoading(true);
		jwtInterceptor.get(baseURL).then((response) => {
            if (response) {
                setPerizia(response.data);
            }
            setLoading(false);
		});
	}, [match.params.periziaId]);

    const history = useHistory()
    const step = parseInt(match.params.step)

    const steps_object = [
        {'adx': 'ANGOLO ANTERIORE DESTRO', 'asx': 'ANGOLO ANTERIORE SINISTRO', 'pdx': 'ANGOLO POSTERIORE DESTRO', 'psx': 'ANGOLO POSTERIORE SINISTRO'},
        {'danni': 'DANNI', 'telaio': 'TELAIO', 'chilometri': 'CHILOMETRI', 'libretto': 'LIBRETTO'},
    ]

    const { photos, takePhoto } = usePhotoGallery(perizia?.id, step);
    const setPeriziaToUpload = async (inputValues) => {
        if(inputValues['importo_perizia'].length > 0){
            Preferences.set({ key: String(perizia.id) + '_form_data', value: JSON.stringify(inputValues) });
        }
        Preferences.set({ key: String(perizia.id) + '_status', value: 'to_upload' });
        perizia['status'] = 'to_upload';
        history.push({pathname: '/perizie', state: { idToUpload: perizia.id }})
    }

    var steps_list = []
    for (const [key, value] of Object.entries(steps_object[step])) {
        steps_list.push([value, key])
    }

    const step_list = steps_list.map((step, index) => {
        var photo_objects = []
        if(photos[step[1]]){
            photo_objects = photos[step[1]].map((photo, index) => (
                <IonThumbnail className="thumbs">
                    <img alt="" src={photo.webviewPath} />
                </IonThumbnail>
            ))
        }
        return (
            <IonList key={index}>
                <IonListHeader>
                    <IonLabel>{step[0]}</IonLabel>
                </IonListHeader>
                <IonItem key={index}>
                {photo_objects}
                <IonButton color='light' className='add-button' onClick={() => takePhoto(step[1])}>
                    <IonIcon size='large' icon={camera}></IonIcon>
                </IonButton>
                </IonItem>
            </IonList>
        )
    });

    useEffect(() => {
        // Verifico che tutte le keys di steps_object siano presenti in photos
		const isPhotoGalleryComplete = () => {
            let step_keys = []
            steps_object.forEach((value) => {
                step_keys = [...step_keys, ...Object.keys(value)]
            })
            const photos_keys = Array.from(new Set(Object.keys(photos)));
            setComplete(compare_arrays(photos_keys, step_keys))
        };
		isPhotoGalleryComplete();
	}, [photos]);

    return (
    <IonPage>
        <MyHeader title={perizia?.targa_veicolo}/>
        <IonContent>
            {step_list}
            <br />
            <IonGrid>
                <IonRow>
                    { isComplete ?
                        <ConfirmAlert confirmFunction={setPeriziaToUpload}
                                      message='Salvo e carico i dati? La perizia non sarà più modificabile.'>
                        </ConfirmAlert> :
                        <WarningAlert message='Insere almeno una foto per tipologia.'/>
                    }
                </IonRow>
                <br/>
                <IonRow>
                    <BackNextButton perizia={perizia} step={step}></BackNextButton>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
    );
};

export default PeriziaEval;
