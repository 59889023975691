import { Redirect, Route } from 'react-router-dom';
import React, {useContext} from 'react'

import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { search, list, exit, callOutline } from 'ionicons/icons';
import Perizie from './pages/Perizie';
import PeriziaDetail from './pages/PeriziaDetail';
import PeriziaEval from 'pages/PeriziaEvaluation';
import Search from './pages/Search';
import LoginPage from './pages/Login';

import CustomTabs from 'components/CustomTabs';

import { AuthProvider } from 'context/AuthContext';
import UIContext from "context/MyContext";
import { UIProvider } from "context/MyContext";
import AuthContext from 'context/AuthContext'

import PrivateRoute from './utils/PrivateRoute'

import perizie from './data/perizie.json';



/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/rentek.css';
import './style/base.css';

setupIonicReact();

var perizie_list = perizie;

const App: React.FC = () => {
  return (
    <IonApp>
    <IonReactRouter>
        <AuthProvider>
          <CustomTabs/>
        </AuthProvider>
    </IonReactRouter>
  </IonApp>
  )};
export default App;
