import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import MyHeader from '../components/MyHeader';
import '../style/pages/search.css';

import { add } from "ionicons/icons";

const Search: React.FC = () => {
  return (
    <IonPage>
    <MyHeader/>
    <IonContent fullscreen>
      <IonSearchbar></IonSearchbar>
    </IonContent>
  </IonPage>
  );
};

export default Search;
