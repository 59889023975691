import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { list } from 'ionicons/icons';
import Resizer from "react-image-file-resizer";

export interface UserPhoto {
    string: [{
        filepath: string;
        webviewPath?: string;
    }]
}

export function usePhotoGallery(perizia_id, step) {
    const [photos, setPhotos] = useState({});

    useEffect(() => {
       if (perizia_id){
         const loadSaved = async () => {
           const { value } = await Preferences.get({ key: perizia_id });
            const photosInPreferences = (value ? JSON.parse(value) : {});

            for (var [key, photo_list] of Object.entries(photosInPreferences) as [string, any]) {
              if(photo_list != undefined){
                for (let photo of photo_list) {
                  const file = await Filesystem.readFile({
                        path: photo.filepath,
                        directory: Directory.Data,
                      });
                      // Web platform only: Load the photo as base64 data
                      photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
                    }
                  }
                }

                setPhotos(photosInPreferences);
              };
          loadSaved();
        }
    }, [perizia_id]);


    function dataURLtoFile(dataurl, filename) {

      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
    }

    const savePicture = async (photo: Photo, fileName: string) => {
        const base64Data = await base64FromPath(photo.webPath!);
        console.log(photo)
        var file = dataURLtoFile(base64Data, fileName);
        console.log(file);
        const resizedImage :any = await resizeFile(file)
        console.log(resizedImage)
        const savedFile = await Filesystem.writeFile({
            path: fileName,
            data: resizedImage,
            directory: Directory.Data,
        });

        // Use webPath to display the new image instead of base64 since it's
        // already loaded into memory
        return {
            filepath: fileName,
            webviewPath: photo.webPath,
            uploaded: false,
        };
    };


    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          2000,
          2000,
          "JPEG",
          60,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      });


    const takePhoto = async (step) => {
        console.log(step)
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        quality: 10,
        saveToGallery: true,
        width: 1500,
        height: 1500,
      });
      const fileName = new Date().getTime() + '.jpeg';
      console.log(photo)
      const savedFileImage = await savePicture(photo, fileName);
      // const resized_file = resizeFile(savedFileImage)
      const newPhotos = {...photos};
      newPhotos[step] = [savedFileImage, ...photos[step] ? photos[step] : []];

      setPhotos(newPhotos);
      Preferences.set({ key: perizia_id, value: JSON.stringify(newPhotos) });
    };

    return {
        photos,
        takePhoto,
    };
}


export async function base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('method did not return a string');
        }
      };
      reader.readAsDataURL(blob);
    });
  }
