import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import jwtInterceptor from '../utils/jwtInterceptor';

import { IonProgressBar, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { IonContent, IonPage, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react';

import MyHeader from '../components/MyHeader';
import DownloadButton from '../components/DownloadButton';
import { defaultSpinner } from '../components/Spinners';

import { Preferences } from '@capacitor/preferences';
import { Filesystem, Directory } from '@capacitor/filesystem';

import '../style/pages/perizie.css';

const Perizie = () => {

  const location = useLocation();
  const [toUpload, setToUpload] = useState();
  const [uploading, setUploading] = useState([]);
  const [uploaded, setUploaded] = useState();
  const [perizie, setPerizie] = useState([]);
  const [loading, setLoading] = useState(false);

  const baseURL = `/api_sinistri_auto/`
  const apiURL = baseURL + 'sinistro/'

  // Invio tutti i dati della perizia al server
  const uploadData = async (perizia) => {
    const url = process.env.REACT_APP_API_URL + apiURL + `${perizia.id}/upload_perizia/`
    // invio prima la stima del danno
    const data = new FormData();
    var { value } = await Preferences.get({ key: String(perizia.id) + '_form_data' });
    var datiPerizia = JSON.parse(value)
    data.append('importo_perizia', datiPerizia.importo_perizia);

    // FOTO
    var { value } = await Preferences.get({ key: String(perizia.id)});
    const photosInPreferences = (value ? JSON.parse(value) : {});
    var errors = false

    const appendFiles = async () => {
      for (const [key, photo_list] of Object.entries(photosInPreferences) as [string, any]) {
        for (const photo of photo_list) {
          if (!photo.uploaded) {
            const fileContent = await Filesystem.readFile({
              path: photo.filepath,
              directory: Directory.Data,
            });
            const file = new File([fileContent.data], photo.filepath);
            data.append('allegato_set', file);
          }
        }
      }
    }

    await appendFiles();
    // Aggiornamento sinistro e allegati
    jwtInterceptor({
      method: "post",
      url: url,
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
      if (response) {
        perizia = response.data
        setUploaded(perizia)
        const p_index = perizie.findIndex((p => String(p.id) == perizia.id));
        perizie[p_index] = perizia
        setPerizie(perizie)
        // Set delle variabili per utilizzo offline ???
        Preferences.set({
          key: String(perizia.id) + '_accordo_conservativo',
          value: perizia.accordo_conservativo
        });
        // In base a questo parametro faccio vedere il button 'inizia valutazione' in PeriziaDetail
        Preferences.set({
          key: 'status_perizia_' + String(perizia.id),
          value: perizia.status_perizia
        });
        Preferences.remove({ key: String(perizia.id) + '_form_data' });
        // Per photoGallery
        Preferences.set({ key: perizia.id, value: JSON.stringify(photosInPreferences) })
        if (errors == false) {
          setPeriziaStatus(perizia.id, 'uploaded');
        }
      }
    }).catch(function (error) {
      errors = true
      console.log(error);
    });
  }

  useEffect(() => {
    if (location.state && location.state['idToUpload']){
      const perizia_id = location.state['idToUpload']
      if (perizia_id !== null){
        const p_index = perizie.findIndex((p => String(p.id) == perizia_id))
        setToUpload(perizie[p_index])
      }
      location.state['idToUpload'] = null
    }
  }, [location.state])

  useEffect(() => {
    if (toUpload) {
      setUploading([...uploading, toUpload])
      uploadData(toUpload)
    }
  }, [toUpload])

  useEffect(() => {
    const newPerizieUploading = uploading.filter(function(ele){
      return ele != uploaded;
    });
    setUploading(newPerizieUploading)
  }, [uploaded])

  var setPeriziaStatus = (perizia_id, status) => {
    Preferences.set({ key: String(perizia_id + '_status'), value: status })
  }

  const [paginated_apiURL, setPageUrl] = useState(`${apiURL}?page=1`);

  const loadPerizie = async () => {
    if (paginated_apiURL) {
      try {
        setLoading(true)
        const response = await jwtInterceptor.get(paginated_apiURL)
        if (response) {
          setPerizie(loadedPerizie => [...loadedPerizie, ...response.data.results]);
          setPageUrl(response.data.next)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  // carico le perizie
  useEffect(() => {
    loadPerizie()
  }, []);

  const rows = perizie.map((perizia) => {
    return(
      <>
          <IonItem key={perizia.id} routerLink={`/perizia/${perizia.id}`}>
            <IonLabel>
              <h2 className='title'>{perizia.targa_veicolo}</h2>
              <IonGrid>
                <IonRow className="perizie-items">
                  <IonCol>
                    Modello
                    <p>
                      {perizia.modello_veicolo}
                    </p>
                  </IonCol>
                  <IonCol>
                    Sinistro
                    <p>
                      {perizia.numero_sinistro}
                    </p>
                  </IonCol>
                  <IonCol>
                    Assicurato
                    <p>
                      {perizia.assicurato}
                    </p>
                  </IonCol>
                  <IonCol>
                    <DownloadButton perizia={perizia} uploaded={uploaded}/>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonLabel>
          </IonItem>
          { uploading.includes(perizia) && <IonProgressBar id={`bar-${perizia.id}`} type="indeterminate"></IonProgressBar> }
      </>
    )
  })


  return (
    <IonPage>
      <MyHeader/>
      <IonContent fullscreen>
        <IonList>{rows}</IonList>
        {loading ? defaultSpinner : null}
        <IonInfiniteScroll onIonInfinite={(ev) => {
          loadPerizie();
          ev.target.complete()
        }}>
          <IonInfiniteScrollContent loadingSpinner={null}></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Perizie;
