export const get_accordo_conservativo = (perizia) => {
    for (var allegato of perizia.allegato_set) {
        if (allegato && allegato.tipo === 'accordo_conservativo') {
            return allegato.file
        }
    }
}

export const compare_arrays = (a, b) => {
    return JSON.stringify(a.sort()) === JSON.stringify(b.sort())
}