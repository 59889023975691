import { useEffect, useState } from 'react';
import { IonPage,
         IonContent,
         IonCard,
         IonCardHeader,
         IonCardTitle,
         IonCardContent,
         IonItem,
         IonLabel,
         IonButton,
        } from '@ionic/react';
import perizie from '../data/perizie.json';
import MyHeader from '../components/MyHeader';

import jwtInterceptor from '../utils/jwtInterceptor';

import { defaultSpinner } from '../components/Spinners';

import '../style/pages/perizia_detail.css';
import {Preferences} from "@capacitor/preferences";

const PeriziaDetail = ({ match }) => {

	const [isEvaluable, setEvaluability] = useState(null);
	const [perizia, setPerizia] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const URL = `/api_sinistri_auto/sinistro/${match.params.periziaId}/`;
    // const perizia = perizie.find(p => p.id == parseInt(match.params.periziaId));

	useEffect(() => {
		const isPeriziaEvaluable = async () => {
			const { value } = await Preferences.get(
				{ key: 'status_perizia_' + String(match.params.periziaId)}
			)
			setEvaluability(value !== 'inviata' && isEvaluable)
		};
		isPeriziaEvaluable();
	});

	useEffect(() => {
		setLoading(true);
		jwtInterceptor.get(URL).then((response) => {
			if (response) {
				setPerizia(response.data);
				setEvaluability(response.data.status_perizia !== 'inviata');
			}
			setLoading(false);
		}).catch((error) => {
			console.log(error)
			if (error.response.status != 401){
				setError(error);
			}
			setLoading(false);
		});
	}, [match.params.periziaId]);

	if ((perizia == null && !loading) || error) {
		return (
			<IonPage>
				<MyHeader />
				<IonContent>
					Nessun dato disponibile per la perizia
				</IonContent>
			</IonPage>
		)
	}
	else{
		return (
		<>
			{ loading ? defaultSpinner :
				<IonPage>
						<MyHeader />
						<IonContent>
								<IonCard>
										<IonCardHeader>
												<IonCardTitle className='perizia-card-title'>{perizia.targa_veicolo}</IonCardTitle>
										</IonCardHeader>
										<IonCardContent className="perizia-card-detail">
												<IonItem>
														<IonLabel>
														<p>Sinistro</p>
														<h2>{perizia.numero_sinistro}</h2>
														</IonLabel>
												</IonItem>
												<IonItem>
														<IonLabel>
														<p>Marca/Modello</p>
														<h2>{perizia.marca} - {perizia.modello_veicolo}</h2>
														</IonLabel>
												</IonItem>
												<IonItem>
														<IonLabel>
														<p>Assicurato</p>
														<h2>{perizia.assicurato}</h2>
														</IonLabel>
												</IonItem>
												<IonItem>
														<IonLabel>
														<p>Indirizzo</p>
														<h2>{perizia.indirizzo_incarico}</h2>
														</IonLabel>
												</IonItem>
										</IonCardContent>
								</IonCard>
								<br />
								{isEvaluable &&
								<IonButton routerLink={`/perizia/${perizia.id}/eval/0`} routerDirection={"forward"}
										   color="secondary" expand="block">Inizia valutazione</IonButton>}
						</IonContent>
				</IonPage>
			}
		</>
		);
	}
};

export default PeriziaDetail;
