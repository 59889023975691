import React, { useState } from 'react';
import { IonAlert, IonButton } from '@ionic/react';

function ConfirmAlert(props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IonAlert
        header={props.message}
        message="Inserisci l'importo della perizia se disponibile"
        trigger="confirm-button"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Alert canceled');
            },
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: (inputValues) => {
              setIsOpen(false);
              props.confirmFunction(inputValues);
            },
          },
        ]}
        inputs={[
          {
            name: 'importo_perizia',
            placeholder: 'Importo perizia',
            type: 'number',
          },
        ]}
      ></IonAlert>
    </>
  );
}
export default ConfirmAlert;
