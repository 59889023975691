import React, {useContext} from 'react'
import { IonPage,
				IonContent,
				IonCard,
				IonInput,
				IonButton,
				IonGrid,
				IonRow,
				IonCol,
				IonImg } from '@ionic/react';

import AuthContext from '../context/AuthContext'
import UIContext from '../context/MyContext'

import '../style/pages/login.css'

const LoginPage = () => {
	var logo = 'assets/img/rentek_logo_blu.png';

	let {loginUser } = useContext(AuthContext)
	return (
		<IonPage>
				<IonContent fullscreen className='ion-text-center'>
					<IonCard className="ion-text-center">
						<IonGrid>
							<IonRow class="ion-justify-content-center">
								<IonCol size="6">
									<IonImg className="logo-login" src={logo} alt=""></IonImg>
								</IonCol>
							</IonRow>
						</IonGrid>
						<h1 className="title">Login</h1>

						<form onSubmit={loginUser}>
								<IonInput label="Username" type="text" name="username" class="form-input" labelPlacement="floating" fill="outline"></IonInput>
								<IonInput label="Password" type="password" name="password" class="form-input" labelPlacement="floating" fill="outline"></IonInput>
								<br />
								<IonButton type="submit" expand="block">Accedi</IonButton>
						</form>
					</IonCard>
				</IonContent>
		</IonPage>
	)
}

export default LoginPage
